import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputField, NoteInputField } from 'components';
import { formatNumber } from 'helpers';
import { useStyles } from '../style';
import FormButtons from './FormButtons';

const CashTradeInForm = ({ offer, onCounterOffer, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const [priceValue, setPriceValue] = useState();

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required(t('required'))
      .min(0, t('priceMinValidation'))
      .max(1000000000, t('priceMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    tradeInValue: Yup.number()
      .required(t('required'))
      .min(0, t('tradeInValueMinValidation'))
      .max(priceValue, t('tradeInValuePriceValidation'))
      .typeError(t('pleaseEnterNumber')),
    internalNotes: Yup.string()
      .max(1000, t('notesValidation'))
  });

  return (
    <Formik
      initialValues={{
        price: offer.price,
        tradeInValue: offer.tradeInValue,
        internalNotes: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onCounterOffer}>
      {({ isSubmitting, values, setFieldValue, dirty }) => (
        <Form className={classes.form}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <p className={classes.formHeader}>Provide your Counter offer details:</p>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <InputField label='price$' inputName='price' type='text' defaultValue={values.price}
                disabled={!!offer.priceLock}
                isRequired={!offer.priceLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.priceLock })}
                onChange={(e) => {
                  const val = formatNumber(e.target.value);
                  setPriceValue(val);
                  setFieldValue('price', val);
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='tradeInV$' inputName='tradeInValue' type='text' defaultValue={values.tradeInValue}
                disabled={!!offer.tradeInValueLock}
                isRequired={!offer.tradeInValueLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.tradeInValueLock })}
                onChange={(e) => {
                  setFieldValue('tradeInValue', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <NoteInputField label='notes' inputName='internalNotes' type='text' className={classes.textareaField} />
            </Grid>
          </Grid>
          <FormButtons disabled={isSubmitting || !dirty} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};

CashTradeInForm.propTypes = {
  offer: PropTypes.object,
  onCounterOffer: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CashTradeInForm;
